import './styles.sass'
import React, { Component } from 'react'
import c from 'classnames'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

export default class TextLink extends Component {
  static propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    to: PropTypes.string,
    children: PropTypes.any,
    selected: PropTypes.bool,
  }

  static defaultProps = {
    className: '',
    style: null,
    to: '/',
    children: null,
    selected: false,
  }

  get className() {
    const { className, selected } = this.props
    return c('dc-text-link', selected && 'selected', className)
  }

  get rest() {
    const props = { ...this.props }
    delete props.children
    delete props.className
    delete props.style
    delete props.to
    delete props.selected
    return props
  }

  render() {
    const { style, to, children } = this.props
    const { className, rest } = this
    return (
      <Link {...rest} to={to} className={className} style={style}>
        <p>{children}</p>
        <div className="line" />
      </Link>
    )
  }
}
