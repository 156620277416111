import './styles.sass'
import React, { Component } from 'react'
import c from 'classnames'
import PropTypes from 'prop-types'

export default class Image extends Component {
  static propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    src: PropTypes.string.isRequired,
    alt: PropTypes.string,
    onClick: PropTypes.func,
  }

  static defaultProps = {
    className: '',
    style: null,
    alt: 'Dark Charm Image',
    onClick: null,
  }

  state = {
    loaded: false,
  }

  componentDidMount = () => {
    this.img.complete && setTimeout(this.setState({ loaded: true }), 100)
  }

  get isData() {
    const { src } = this.props
    return src.trim().indexOf('data:') === 0
  }

  get className() {
    const { className } = this.props
    const { loaded } = this.state
    return c('dc-image', loaded && 'loaded', className)
  }

  render() {
    const { style, src, alt, onClick } = this.props
    const { className } = this
    return (
      <div className={className} style={style}>
        <img
          ref={img => (this.img = img)}
          src={src}
          alt={alt}
          onLoad={() => this.setState({ loaded: true })}
        />
        <div onClick={onClick} style={{ backgroundImage: `url(${src})` }} />
      </div>
    )
  }
}
