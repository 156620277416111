import './styles.sass'
import React, { Component } from 'react'
import { Link } from 'gatsby'
import c from 'classnames'
import PropTypes from 'prop-types'
import Image from 'src/components/ui/image'
import TextLink from 'src/components/ui/text-link'
import textLogo from 'src/images/dark-charm-logo-text.svg'

export default class Header extends Component {
  static propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    pageId: PropTypes.string.isRequired,
  }

  static defaultProps = {
    className: '',
    style: null,
  }

  get className() {
    const { className } = this.props
    return c('dc-header', className)
  }

  render() {
    const { style, pageId } = this.props
    const { className } = this
    return (
      <header className={className} style={style}>
        <Link to="/">
          <Image className="logo" src={textLogo} alt="Dark Charm Logo" />
        </Link>
        <div className="links">
          <TextLink className="link" selected={pageId === 'work'} to="/work">
            WORK
          </TextLink>
          <TextLink
            className="link"
            selected={pageId === 'contact'}
            to="/contact"
          >
            CONTACT
          </TextLink>
        </div>
      </header>
    )
  }
}
