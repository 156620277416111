import 'src/common/reset.sass'
import 'src/common/fonts.sass'
import 'src/common/styles.sass'
import './styles.sass'
import React, { Component } from 'react'
import c from 'classnames'
import PropTypes from 'prop-types'
import SEO from './seo'
import Header from 'src/components/ui/header'
import Footer from 'src/components/ui/footer'
import { Location } from '@reach/router'

export class Page extends Component {
  static propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    children: PropTypes.any,
    title: PropTypes.string,
    description: PropTypes.string,
    id: PropTypes.string.isRequired,
  }

  static defaultProps = {
    className: '',
    style: null,
    children: null,
    title: '',
    description: '',
  }

  state = {
    loaded: false,
  }

  componentDidMount = () => {
    setTimeout(() => this.setState({ loaded: true }), 250)
  }

  get className() {
    const { className } = this.props
    const { loaded } = this.state
    return c('dc-page', loaded && 'loaded', className)
  }

  render() {
    const { style, children, title, id } = this.props
    const { className } = this
    return (
      <div className={className} style={style}>
        <SEO title={title} />
        <Header pageId={id} />
        <section>{children}</section>
        <Footer pageId={id} />
      </div>
    )
  }
}

export default props => (
  <Location>{locationProps => <Page {...locationProps} {...props} />}</Location>
)
