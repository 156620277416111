import './styles.sass'
import React, { Component } from 'react'
import c from 'classnames'
import { Link } from 'gatsby'
import validator from 'validator'
import PropTypes from 'prop-types'
import Image from 'src/components/ui/image'
import TextLink from 'src/components/ui/text-link'
import logoSrc from 'src/images/dark-charm-logo-icon.svg'
import submitSrc from 'src/images/email-submit-btn.svg'
import socialLinkedin from 'src/images/social-linkedin.svg'
import socialVimeo from 'src/images/social-vimeo.svg'
import socialYoutube from 'src/images/social-youtube.svg'
import socialInstagram from 'src/images/social-instagram.svg'

export default class Footer extends Component {
  static propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
  }

  static defaultProps = {
    className: '',
    style: null,
  }

  state = {
    email: '',
    valid: false,
    submitted: false,
  }

  onSubmit = event => {
    event.preventDefault()
    if (!this.state.valid) return
    document.querySelector('input[name="email"]').blur()
    this.setState({ submitted: true })
  }

  onEmailChange = (event, value) => {
    this.setState({
      email: event.target.value,
      valid: validator.isEmail(event.target.value),
    })
  }

  get className() {
    const { className } = this.props
    const { valid, submitted } = this.state
    return c('dc-footer', valid && 'valid', submitted && 'submitted', className)
  }

  render() {
    const { style } = this.props
    const { email, submitted } = this.state
    const { className, onEmailChange, onSubmit } = this

    return (
      <footer className={className} style={style}>
        <div className="border" />
        <div className="container-1">
          <div className="container-1">
            <Link className="home-link" to="/">
              <Image
                className="logo"
                src={logoSrc}
                alt="Dark Charm Logo for Footer"
              />
            </Link>
            <div className="links">
              <TextLink className="link" to="/">
                HOME
              </TextLink>
              <TextLink className="link" to="/work">
                WORK
              </TextLink>
              <TextLink className="link" to="/contact">
                CONTACT
              </TextLink>
            </div>
          </div>
          <div className="container-2">
            <div className="sign-up">
              <h3>Sign up to the Dark Charm newsletter</h3>
              <form onSubmit={onSubmit}>
                <input
                  onChange={onEmailChange}
                  name="email"
                  type="text"
                  value={submitted ? "THANK YOU! WE'LL BE IN TOUCH" : email}
                  placeholder="E-MAIL ADDRESS"
                />
                <Image
                  onClick={onSubmit}
                  className="submit-btn"
                  src={submitSrc}
                  alt="Submit Subscribe Form"
                />
              </form>
            </div>
            <div className="socials">
              <a href="/?social=linkedin" target="_blank">
                <Image
                  className="social-icon linkedin"
                  src={socialLinkedin}
                  alt="Social Icon LinkedIn"
                />
              </a>
              <a href="/?social=vimeo" target="_blank">
                <Image
                  className="social-icon vimeo"
                  src={socialVimeo}
                  alt="Social Icon Vimeo"
                />
              </a>
              <a href="/?social=youtube" target="_blank">
                <Image
                  className="social-icon youtube"
                  src={socialYoutube}
                  alt="Social Icon YouTube"
                />
              </a>
              <a href="/?social=instagram" target="_blank">
                <Image
                  className="social-icon instagram"
                  src={socialInstagram}
                  alt="Social Icon Instagram"
                />
              </a>
            </div>
          </div>
        </div>
        <div className="container-2">
          <div className="copyright">2019 © dark charm</div>
        </div>
      </footer>
    )
  }
}
