import React, { Component } from 'react'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'

export default class SEO extends Component {
  static propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
  }

  static defaultProps = {
    title: '',
    description: 'We illuminate and elevate your digital experiences',
  }

  render() {
    let { title, description } = this.props
    title = title ? `${title} | Dark Charm` : `Dark Charm`

    return (
      <Helmet
        htmlAttributes={{
          lang: 'en',
        }}
        title={title}
        meta={[
          {
            name: `description`,
            content: description,
          },
          {
            property: `og:title`,
            content: title,
          },
          {
            property: `og:description`,
            content: description,
          },
          {
            property: `og:type`,
            content: `website`,
          },
          {
            name: `twitter:card`,
            content: `summary`,
          },
          {
            name: `twitter:creator`,
            content: 'author',
          },
          {
            name: `twitter:title`,
            content: title,
          },
          {
            name: `twitter:description`,
            content: description,
          },
        ]}
      />
    )
  }
}
